import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomErrorsTextPipe } from '../product-capture/create-product/legal-form/custom-errors-text.pipe';
import { BooleanToYesNoPipe } from './pipes/boolean-to-yes-no.pipe';
import { RoundAndFormatPipe } from './pipes/round-and-format.pipe';
import { ValueOrDashPipe } from './pipes/value-or-dash.pipe';
import { MultilineLabelPipe } from './pipes/multiline-label.pipe';

@NgModule({
  declarations: [
    BooleanToYesNoPipe,
    ValueOrDashPipe,
    RoundAndFormatPipe,
    CustomErrorsTextPipe,
    MultilineLabelPipe,
  ],
  imports: [CommonModule],
  exports: [
    BooleanToYesNoPipe,
    ValueOrDashPipe,
    RoundAndFormatPipe,
    CustomErrorsTextPipe,
    MultilineLabelPipe,
  ],
})
export class SharedModule {}
