import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiKitModule } from '../ui-kit/ui-kit.module';
import { AuthO3RoutingModule } from './auth-o3-routing.module';
import { AuthO3Component } from './auth-o3.component';
import { declarations } from './core';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AuthO3Component,
    PageNotFoundComponent,
    declarations,
  ],
  imports: [
    CommonModule,
    AuthO3RoutingModule,
    FontAwesomeModule,
    NgbModule,
    UiKitModule,
    ScrollingModule,
    AmplifyAuthenticatorModule,
  ],
  exports: [
    AuthO3Component,
  ],
})
export class AuthO3Module {
  constructor() {}
}
