import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterLink } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map, take } from 'rxjs/operators';

export const redirectGuard: CanActivateFn = () => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  return authService.getEnvironmentName().pipe(
    take(1),
    map((name) => {
      if (name === 'Ops') {
        router.navigate(['/release-management']);
        return true;
      }
      router.navigate(['/product-capture']);
      return true;
    })
  );
};
