import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment as env } from "src/environments/environment";
import { NotificationMessage } from "./notifications.model";

@Injectable({ providedIn: 'root'})
export class NotificationsService {

    private ws: WebSocket;

    onMessage$ = new Subject<NotificationMessage>();

    start(): void {
        this.ws = new WebSocket(env.api.notification);

        this.ws.onopen = () => {
            console.log('Websocket connection established');
        }

        this.ws.onmessage = (event) => {
            console.log('Received notification:', event.data);
            this.onMessage$.next(JSON.parse(event.data));
        }

        this.ws.onclose = () => {
            console.log('Websocket connection closed');
        }

        this.ws.onerror = (error) => {
            console.error('Websocket failed', error);
        }
    }
}