import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { ROUTE_NAMES } from './app-routes.const';
import { restrictIfFactory } from './auth-o3/shared/restrict/restrict-if.factory';

const { restrictIfNot } = restrictIfFactory();

const routes: Routes = [
  {
    path: 'auth-o3',
    loadChildren: () =>
      import('./auth-o3/auth-o3.module').then((m) => m.AuthO3Module)
  },
  {
    path: ROUTE_NAMES.PRODUCT_CAPTURE,
    loadChildren: () =>
      import('./product-capture/product-capture.module').then(
        (m) => m.ProductCaptureModule
      )
  },
  {
    path: ROUTE_NAMES.LIFECYCLE_EVENTS,
    loadChildren: () =>
      import('./lifecycle-events/lifecycle-events.module').then(
        (m) => m.LifecycleEventsModule
      ),
    canActivate: [restrictIfNot('VIEW_EVENTS')],
  },
  {
    path: ROUTE_NAMES.STATIC_DATA,
    loadChildren: () =>
      import('./static-data/static-data.module').then(
        (m) => m.StaticDataModule
      ),
    canActivate: [restrictIfNot('VIEW_STATIC_DATA')],
  },
  {
    path: ROUTE_NAMES.RELEASE_MANAGEMENT,
    loadChildren: () =>
      import('./release-management/release-management.module').then(
        (m) => m.ReleaseManagementModule
      ),
    canActivate: [restrictIfNot('VIEW_RELEASE_MANAGEMENT_SECTION')],
  },
  {
    path: ROUTE_NAMES.ENRICHMENT,
    loadChildren: () =>
      import('./data-enrich/data-enrich.module').then(
        (m) => m.DataEnrichModule
      ),
    canActivate: [restrictIfNot('VIEW_ENRICHMENT_CONFIG')],
  },
  {
    path: ROUTE_NAMES.INGESTION,
    loadChildren: () =>
      import('./ingestion/ingestion.module').then((m) => m.IngestionModule),
    canActivate: [ restrictIfNot('VIEW_INGESTION_CONFIG') ],
  },

  {
    path: ROUTE_NAMES.RULES,
    loadChildren: () =>
      import('./rules/rules.module').then((m) => m.RulesModule),
    canActivate: [restrictIfNot('VIEW_RULES')],
  },
  {
    path: ROUTE_NAMES.VALIDATION,
    loadChildren: () =>
      import('./validation/validation.module').then((m) => m.ValidationModule),
    canActivate: [restrictIfNot('VIEW_RULES')],
  },
  {
    path: ROUTE_NAMES.REGRESSION,
    loadChildren: () =>
      import('./regression/regression.module').then((m) => m.RegressionModule),
    canActivate: [restrictIfNot('VIEW_REGRESSION_SECTION')],
  },
  {
    path: ROUTE_NAMES.WORKFLOWS,
    loadChildren: () =>
      import('./workflows/workflows.module').then((m) => m.WorkflowsModule),
    canActivate: [restrictIfNot('VIEW_ADMIN_SECTION')]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
