<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 355.5 99.1" style="enable-background:new 0 0 355.5 99.1;" xml:space="preserve">
<style type="text/css">
	.st0{
        fill: {{ mainColor }};
    }
	.st1{
        fill: {{ secondaryColor }};
    }
</style>
<g id="Layer_2_1_">
	<g id="Layer_1-2">
		<path class="st0" d="M0,0h15.4v13.5H0V0z M0.2,22.4h15v54.2h-15V22.4z"/>
		<path class="st0" d="M79.4,41.1v35.5H64.6V45.9c0-4,0-11.7-9.3-11.7C44,34.2,44,45.3,44,53.1v23.5H29V22.4h15v5.6
			c4-5.1,9.7-6.8,15.8-6.8C72.2,21.2,79.4,28.7,79.4,41.1z"/>
		<path class="st1" d="M86.4,62.4l11.8-5.3c1.3,3.4,3.4,8.6,10.8,8.6c5.4,0,7.4-2.8,7.4-5c0-8.4-26.9-3.4-26.9-23.1
			c0-11.3,10-16.4,20.1-16.4c9.7,0,16.8,4.6,21,11.9l-11.4,6.4c-1.7-3-4.2-6.9-9.9-6.9c-3.6,0-5.7,1.8-5.7,3.8
			c0,7.7,27.5,2.6,27.5,22.8c0,13.1-11.4,18.7-21.7,18.7C97.7,77.9,89,70.4,86.4,62.4z"/>
		<path class="st1" d="M193,49.6c0,18.2-10,28.3-22.6,28.3c-5.6,0-11.4-2.3-14.9-6.1v27.3h-15V22.4h15v4.6c3.9-4.2,9.7-5.8,14.9-5.8
			C185.1,21.2,193,33.5,193,49.6z M178.1,49.5c0-10-3.7-15.2-11.4-15.2c-9.2,0-12.5,6.5-12.5,15.2s3.5,15.3,12.3,15.3
			C174.3,64.8,178.2,59.5,178.1,49.5L178.1,49.5z"/>
		<path class="st0" d="M202.5,0h15.4v13.5h-15.4V0z M202.7,22.4h15v54.2h-15V22.4z"/>
		<path class="st0" d="M271.2,24.5L264,37.8c-2.1-1.2-4.4-1.9-6.8-1.8c-6.9,0-10.8,4.5-10.8,14.4v26.3h-15V22.4h15.1V31
			c2.2-5.8,7.8-9.7,14.1-9.8C264.4,21.2,268.1,22.4,271.2,24.5z"/>
		<path class="st0" d="M320.5,54.5h-36.4c0.7,5.7,4.5,9.8,12.3,9.8c4.7,0.1,9.2-1.9,12.2-5.6l10.3,9.1c-5.7,6.5-14,10.2-22.6,10
			c-19.4,0-27.1-13.9-27.1-28c0-13.9,7.5-28.6,26.2-28.6c16.9,0,25.4,12.3,25.4,28.2C320.8,51.1,320.7,52.8,320.5,54.5z M306.6,43.2
			c-0.2-5.9-5.2-9.6-11.3-9.6c-7.6,0-10.7,5.2-11.3,9.6H306.6z"/>
		<polygon class="st1" points="331.9,77.9 355.5,61.1 331.9,44.3 		"/>
	</g>
</g>
</svg>
<ng-container *ngIf="environment?.toLowerCase() as env">
	<div class="tag" [style.backgroundColor]="secondaryColor" *ngIf="env === 'ops' || env === 'test'">
		{{ env === 'test' ? 'UAT' : 'OPS' }}
	</div>
</ng-container>
