export const LogoColors = {
  DEFAULT: {
    mainColor: '#FFF',
    secondaryColor: 'var(--logo-default-color)',
  },
  DEV: {
    secondaryColor: 'var(--logo-dev-color)',
  },
  OPS: {
    secondaryColor: 'var(--logo-dev-color)',
  },
  TEST: {
    secondaryColor: 'var(--logo-test-color)',
  },
};
