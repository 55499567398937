export interface FeatureFlag {
    featureName: string;
    isEnabled: boolean;
}

// The following keys must match the keys in the database. So they have to be registered in the feature flag page in the admin panel with the same name.
export enum FeatureKeys {
    GOVERNANCE_REPORTS = 'GOVERNANCE_REPORT_WORKFLOW',
    DOC_GEN = 'DOC_GEN_WORKFLOW',
    ANALYTICS = 'ANALYTICS_WORKFLOW'
}