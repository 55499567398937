import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpMethod } from "src/app/common-types/common-types.consts";
import { environment } from "src/environments/environment";
import { FeatureFlagInterceptor } from "../feature-flag.interceptor";
import { FeatureKeys } from "../feature-flag.model";

@Injectable({
    providedIn: 'root'
})
export class GovernanceReportsInterceptor extends FeatureFlagInterceptor {

    shouldIntercept(request: HttpRequest<unknown>): Observable<boolean> {
        const isGovernanceReportRequest = request.url.includes('governance-tests');
        if (!isGovernanceReportRequest || (isGovernanceReportRequest && request.method !== HttpMethod.POST)) {
            return of(false);
        }

        return this.featureFlagService.getFeatureFlag(FeatureKeys.GOVERNANCE_REPORTS)
            .pipe(
                map(({ isEnabled }) => isEnabled)
            );
        
    }

    handleRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
        const { body }: any = request;
        const { productId } = body;

        return request.clone({
            url: `${environment.workflow.api.processApi}/runtime/process-instances`,
            body: {
                processDefinitionKey: 'governanceCheck',
                businessKey: `governanceCheck-${productId}`,
                variables: [{
                    name: 'productId',
                    type: 'integer',
                    value: productId
                }]
            }
        });
    }
}