import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { RestrictIfService } from './shared/restrict/restrict-if.service';
import { AuthService } from './shared/services/auth.service';
import { CurrentUserService } from './shared/services/current-user.service';

@Component({
  selector: 'app-auth-o3',
  templateUrl: './auth-o3.component.html',
  styleUrls: ['./auth-o3.component.scss'],
})
export class AuthO3Component implements OnInit, OnDestroy {
  loading = true;
  navigating = false;
  loggedInAsDistributor = false;
  signedIn = false;
  user: string = undefined;
  email: string = undefined;
  closePanels = false;
  destroy$ = new Subject();

  appVersion = 'APP_VERSION_PLACEHOLDER';
  appBuildNumber = 'APP_BUILD_NUMBER_PLACEHOLDER';
  appBuildTime = parseInt('APP_BUILD_TIME_PLACEHOLDER', 10) || 1584435059600;
  environment = '';

  appBuildDateString: string;
  public formFields = {
    signIn: {
      username: {
        placeholder: 'Username',
        isRequired: true,
        label: 'Username:',
      },
      password: {
        placeholder: 'Password',
        isRequired: true,
        label: 'Password:',
      },
    },
    resetPassword: {
      username: {
        placeholder: 'Username',
        isRequired: true,
        label: 'Username:',
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: 'inSPire Platform',
      },
    },
  };
  constructor(
    private currentUserService: CurrentUserService,
    public authService: AuthService,
    public authenticator: AuthenticatorService,
    public restrictService: RestrictIfService,
    private cdr: ChangeDetectorRef
  ) {
    try {
      if (this.appVersion.startsWith('APP_')) {
        this.appVersion = 'DEV-BUILD';
      }
      if (this.appBuildNumber.startsWith('APP_')) {
        this.appBuildNumber = 'DEV';
      }
      const dateTime = DateTime.fromMillis(this.appBuildTime);
      this.appBuildDateString = dateTime.toLocaleString({
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
    } catch (e) {
      console.error('Failed to determine application version', e);
    }

    this.authService.onMfaEnvironment.subscribe((environment) => {
      this.environment = environment;
      this.formFields.setupTOTP.QR.totpIssuer =
        'inSPire Platform - ' + environment;
    });

    this.authService.onUserSession.subscribe((session) => {
      this.signedIn = session?.isValid();
      this.user =
        session?.isValid() && session.idToken.payload['cognito:username'];
      this.email = session?.isValid() && session.idToken.payload.email;
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async signOut() {
    this.currentUserService.currentUser.subscribe(async (currentUser) => {
      await this.authService.signOut();
    });
  }

  onLoaded() {
    this.loading = false;
    this.cdr.detectChanges();
  }
}
