export enum LifecycleEventStatusKey {
  UPCOMING = 'UPCOMING',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
  IN_REVIEW = 'IN_REVIEW',
  CLIENT_REVIEW = 'CLIENT_REVIEW',
  CANCELLED = 'CANCELLED',
}

export enum LifecycleEventStatus {
  DEFERRED = 'Deferred',
  PROCESSING = 'Processing',
  COMPLETE = 'Complete',
  DUE = 'Due',
  NOT_YET_DUE = 'Not yet due',
  UPCOMING = 'Upcoming',
  IN_REVIEW = 'In Review',
  CLIENT_REVIEW = 'Client Review',
  LIVE = 'Live',
  REDEEMED = 'Redeemed',
  MATURED = 'Matured',
  EARLY_REDEMPTION = 'Early redemption',
  CANCELLED = 'Cancelled',
}
