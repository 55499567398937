import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, first } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "../auth-o3/shared/services/api.service";
import { FeatureFlag } from "./feature-flag.model";

const HOSTNAME = `${environment.api.staticData}`;

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {

    constructor(private apiService: ApiService) { }

    getFeatureFlags(): Observable<any> {
        return this.apiService.get<any>('features', HOSTNAME).pipe(first());
    }

    getFeatureFlag(featureName: string): Observable<FeatureFlag> {
        return this.apiService.get<FeatureFlag>(`feature/${featureName}`, HOSTNAME)
            .pipe(
                first(),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        return of({ isEnabled: false, featureName });
                    }
                }),
            );
    }

    addFeature(feature: FeatureFlag): Observable<boolean> {
        return this.apiService.post<FeatureFlag, boolean>('feature', feature, HOSTNAME).pipe(first());
    }

    toggleFeature(feature: FeatureFlag): Observable<boolean> {
        return this.apiService.put<FeatureFlag, boolean>(`feature`, feature, HOSTNAME).pipe(first());
    }

    deleteFeature(feature: FeatureFlag): Observable<boolean> {
        return this.apiService.delete<boolean>(`feature/${feature.featureName}`, {}, HOSTNAME).pipe(first());
    }
}