import { HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { map } from "rxjs/operators"
import { HttpMethod } from "src/app/common-types/common-types.consts"
import { environment } from "src/environments/environment"
import { FeatureFlagInterceptor } from "../feature-flag.interceptor"
import { FeatureKeys } from "../feature-flag.model"

@Injectable()
export class AnalyticsFeatureInterceptor extends FeatureFlagInterceptor {

    shouldIntercept(request: any): Observable<boolean> {
        const isAnalytics = request.url.includes(environment.api.analyticsService);
        if (!isAnalytics || (isAnalytics && request.method !== HttpMethod.POST)) {
            return of(false)
        }

        return this.featureFlagService.getFeatureFlag(FeatureKeys.ANALYTICS)
            .pipe(
               map(({ isEnabled }) => !!isEnabled)
            )
    }

    handleRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
        const { productId } = request.body as any;

        return request.clone({
            url: `${environment.workflow.api.processApi}/runtime/process-instances`,
            body: {
                processDefinitionKey: 'fvcRequest3',
                businessKey: `${productId}-fvcRequest3`,
                variables: this.mapProperties(request.body)
            },
        })
    }

    mapProperties(body: any): any[] {
        return Object.keys(body).reduce((acc, key) => {
            let type: string = typeof body[key];

            if (type === 'number') {
                type = 'integer';
            }

            let value = body[key];

            if (key === 'requestType') {
                key = 'integrationType';

                switch (value) {
                    case 'fvc':
                        value = 'fvcStress';
                        break;
                    case 'fvcPRIIPs':
                        value = 'fvcPriips';
                        break;
                    case 'cloudRisk':
                        value = 'cloudRiskPriips';
                        break;
                }
            }

            acc.push({
                name: key,
                type,
                value
            })

            return acc;
        }, []);
    }
}