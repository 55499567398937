import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagInterceptor implements HttpInterceptor {

  featureFlagService = inject(FeatureFlagService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.shouldIntercept(request)
      .pipe(
        switchMap((shouldIntercept) => {
          if (shouldIntercept) {
            const { username, password } = environment.workflow.settings;
            const encodedCredentials = btoa(`${username}:${password}`);

            request = this.handleRequest(request).clone({
              setHeaders: {
                  'Authorization': `Basic ${encodedCredentials}`, // Hardcoded for demo purposes
                  'Content-Type': 'application/json',
              },
              withCredentials: true,
            });
          }
          return next.handle(request);
        })
      );
  }

  handleRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone();
  }

  // Must be implemented in child classes
  shouldIntercept(request: HttpRequest<unknown>): Observable<boolean> {
    return of(false);
  }
}
