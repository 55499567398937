import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthO3Module } from './auth-o3/auth-o3.module';
import { RestrictIfService } from './auth-o3/shared/restrict/restrict-if.service';
import { AuthSettingsService } from './auth-o3/shared/services/auth-settings.service';
import { AuthService } from './auth-o3/shared/services/auth.service';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { featureInterceptors } from './feature-flag/feature-interceptors';
import { NotificationsService } from './notifications/notification.service';
import { UiKitModule } from './ui-kit/ui-kit.module';

export const configureAuth =
  (httpClient: HttpClient, settingsService: AuthSettingsService) => async () =>
    await AuthService.configure(httpClient, settingsService);

const connectToNotifications = (notificationsService: NotificationsService) => () => notificationsService.start();

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    UiKitModule,
    LoggerModule.forRoot({
      enableSourceMaps: true,
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    AuthO3Module,
  ],
  providers: [
    AuthSettingsService,
    RestrictIfService,
    {
      provide: APP_INITIALIZER,
      deps: [NotificationsService],
      useFactory: connectToNotifications,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [
        HttpClient, 
        AuthSettingsService,
        NotificationsService
      ],
      useFactory: configureAuth,
      multi: true,
    },
    AuthService,
    FeatureFlagService,
    ...featureInterceptors,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}