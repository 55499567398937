import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDateParserFormatter, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { IConfig, NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { CalendarModule } from 'primeng/calendar';
import { RestrictionsModule } from '../auth-o3/shared/restrict/restrictions.module';
import { FilterPerformanceTypePipe } from '../product-capture/create-product/product-summary/underlying-assets-modal/pipes/filter-performance-type.pipe';
import { SharedModule } from '../shared/shared.module';
import { DcAddTagsComponent } from './dc-add-tags/dc-add-tags.component';
import { DcAlertInnerComponent } from './dc-alert-inner/dc-alert-inner.component';
import { DcAlertComponent } from './dc-alert/dc-alert.component';
import { DcAssetDisplayComponent } from './dc-asset-display/dc-asset-display.component';
import { DcCheckboxComponent } from './dc-checkbox/dc-checkbox.component';
import { DcCompanyAddressCardComponent } from './dc-company-address-card/dc-company-address-card.component';
import { DcCompanyInfoHeaderComponent } from './dc-company-info-header/dc-company-info-header.component';
import { DcCompanyRequestCardComponent } from './dc-company-request-card/dc-company-request-card.component';
import { DcControlValidationComponent } from './dc-control-validation/dc-control-validation.component';
import { DcCsvDownloadButtonComponent } from './dc-csv-download-button/dc-csv-download-button.component';
import { DcDateInputRangeComponent } from './dc-date-input-range/dc-date-input-range.component';
import { DcDateInputComponent } from './dc-date-input/dc-date-input.component';
import { NgbDateGBParserFormatter } from './dc-date-input/ngb-date-gb-parser-formatter';
import { DcDisplayInputComponent } from './dc-display-input/dc-display-input.component';
import { DcDownloadCsvComponent } from './dc-download-csv/dc-download-csv.component';
import { DcFileUploadComponent } from './dc-file-upload/dc-file-upload.component';
import { FileDragAndDropDirective } from './dc-file-upload/file-drag-and-drop.directive';
import { DcHeaderComponent } from './dc-header/dc-header.component';
import { DcInputNumberPercentIndicativeFinalComponent } from './dc-input-number-percent-indicative-final/dc-input-number-percent-indicative-final.component';
import { DcInputNumberPercentComponent } from './dc-input-number-percent/dc-input-number-percent.component';
import { DcInputNumericComponent } from './dc-input-numeric/dc-input-numeric.component';
import { DcInputComponent } from './dc-input/dc-input.component';
import { DcLoadingComponent } from './dc-loading/dc-loading.component';
import { LogoComponent } from './dc-logo/logo.component';
import { DcModalComponent } from './dc-modal/dc-modal.component';
import { DcMonacoDiffComponent } from './dc-monaco-diff/dc-monaco-diff.component';
import { DcMonacoStandaloneComponent } from './dc-monaco-standalone/dc-monaco-standalone.component';
import { DcNavLinkComponent } from './dc-nav-link/dc-nav-link.component';
import { DcOriginalAndEditedDateInputComponent } from './dc-original-and-edited-date-input/dc-original-and-edited-date-input.component';
import { DcPaneComponent } from './dc-pane/dc-pane.component';
import { DcPdfViewerComponent } from './dc-pdf-viewer/dc-pdf-viewer.component';
import { DcPlusMinusComponent } from './dc-plus-minus/dc-plus-minus.component';
import { DcPopoverComponent } from './dc-popover/dc-popover.component';
import { DcProductIdentifierModalComponent } from './dc-product-identifier-modal/dc-product-identifier-modal.component';
import { DcProgressBarComponent } from './dc-progress-bar/dc-progress-bar.component';
import { DcRadioCustomComponent } from './dc-radio-custom/dc-radio-custom.component';
import { DcRadioComponent } from './dc-radio/dc-radio.component';
import { DcReferenceDisplayComponent } from './dc-reference-display/dc-reference-display.component';
import { DcRequestCardComponent } from './dc-request-card/dc-request-card.component';
import { DcScheduleComponent } from './dc-schedule/dc-schedule.component';
import { DcSearchComponent } from './dc-search/dc-search.component';
import { DcSectionButtonComponent } from './dc-section-button/dc-section-button.component';
import { DcSelectComponent } from './dc-select/dc-select.component';
import { SelectLabelPipe } from './dc-select/select-label.pipe';
import { SelectTitlePipe } from './dc-select/select-title.pipe';
import { DcSpinnerComponent } from './dc-spinner/dc-spinner.component';
import { DcSplitButtonComponent } from './dc-split-button/dc-split-button.component';
import { DcTableValidationComponent } from './dc-table-validation/dc-table-validation.component';
import { ContextMenuComponent } from './dc-table/context-menu/context-menu.component';
import { DcTableSearchComponent } from './dc-table/dc-table-search.component';
import { DcTableComponent } from './dc-table/dc-table.component';
import { FormatAWSUsernamePipe } from './dc-table/format-aws-username.pipe';
import { NgxResizeWatcherDirective } from './dc-table/ngx-resize-watcher.directive';
import { DcTextareaComponent } from './dc-textarea/dc-textarea.component';
import { DcTimelineComponent } from './dc-timeline/dc-timeline.component';
import { DcToggleComponent } from './dc-toggle/dc-toggle.component';
import { GetValidationStatusPipe } from './pipes/get-validation-status.pipe';
import { NumberStringToTwoDecimalsPipe } from './pipes/number-string-to-two-decimals.pipe';
import { FunctionWrapperPipe } from './pipes/wrapper.pipe';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    DcAssetDisplayComponent,
    DcTableComponent,
    DcModalComponent,
    DcSelectComponent,
    DcSplitButtonComponent,
    DcPopoverComponent,
    DcDateInputComponent,
    DcOriginalAndEditedDateInputComponent,
    DcAlertComponent,
    DcInputComponent,
    DcCheckboxComponent,
    DcRadioComponent,
    DcTextareaComponent,
    DcTableSearchComponent,
    NgxResizeWatcherDirective,
    DcPaneComponent,
    DcHeaderComponent,
    DcProgressBarComponent,
    DcNavLinkComponent,
    DcCompanyInfoHeaderComponent,
    DcCompanyRequestCardComponent,
    DcDateInputRangeComponent,
    DcLoadingComponent,
    DcRequestCardComponent,
    DcCompanyAddressCardComponent,
    DcSearchComponent,
    DcScheduleComponent,
    DcProductIdentifierModalComponent,
    DcRadioCustomComponent,
    DcFileUploadComponent,
    DcTimelineComponent,
    DcInputNumberPercentComponent,
    DcAlertInnerComponent,
    DcControlValidationComponent,
    DcCsvDownloadButtonComponent,
    DcDisplayInputComponent,
    DcReferenceDisplayComponent,
    DcSpinnerComponent,
    SelectLabelPipe,
    SelectTitlePipe,
    FunctionWrapperPipe,
    DcPlusMinusComponent,
    ContextMenuComponent,
    LogoComponent,
    DcToggleComponent,
    DcMonacoStandaloneComponent,
    DcInputNumberPercentIndicativeFinalComponent,
    DcSectionButtonComponent,
    DcDownloadCsvComponent,
    DcTableValidationComponent,
    FilterPerformanceTypePipe,
    FileDragAndDropDirective,
    DcInputNumericComponent,
    NumberStringToTwoDecimalsPipe,
    GetValidationStatusPipe,
    DcPdfViewerComponent,
    DcAddTagsComponent,
    DcMonacoDiffComponent,
    FormatAWSUsernamePipe,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgSelectModule,
    FontAwesomeModule,
    CalendarModule,
    //NgxMaskModule.forRoot(),
    NgxMaskDirective,
    SharedModule,
    NgxExtendedPdfViewerModule,
    RestrictionsModule,
    NgbTooltipModule
  ],
  exports: [
    DcAssetDisplayComponent,
    DcTableComponent,
    DcModalComponent,
    DcSelectComponent,
    DcSplitButtonComponent,
    DcPopoverComponent,
    DcDateInputComponent,
    DcOriginalAndEditedDateInputComponent,
    DcAlertComponent,
    DcInputComponent,
    DcCheckboxComponent,
    DcRadioComponent,
    DcTextareaComponent,
    DcTableSearchComponent,
    DcPaneComponent,
    DcHeaderComponent,
    DcProgressBarComponent,
    DcNavLinkComponent,
    DcCompanyInfoHeaderComponent,
    DcCompanyRequestCardComponent,
    DcDateInputRangeComponent,
    DcLoadingComponent,
    DcRequestCardComponent,
    DcCompanyAddressCardComponent,
    DcSearchComponent,
    DcScheduleComponent,
    DcProductIdentifierModalComponent,
    DcRadioCustomComponent,
    DcFileUploadComponent,
    DcTimelineComponent,
    DcInputNumberPercentComponent,
    DcControlValidationComponent,
    DcAlertInnerComponent,
    DcCsvDownloadButtonComponent,
    DcDisplayInputComponent,
    DcReferenceDisplayComponent,
    DcSpinnerComponent,
    FunctionWrapperPipe,
    DcPlusMinusComponent,
    LogoComponent,
    DcToggleComponent,
    DcMonacoStandaloneComponent,
    DcInputNumberPercentIndicativeFinalComponent,
    DcSectionButtonComponent,
    DcDownloadCsvComponent,
    DcTableValidationComponent,
    FilterPerformanceTypePipe,
    FileDragAndDropDirective,
    DcInputNumericComponent,
    NumberStringToTwoDecimalsPipe,
    GetValidationStatusPipe,
    DcPdfViewerComponent,
    DcAddTagsComponent,
    DcMonacoDiffComponent,
    RestrictionsModule,
    FormatAWSUsernamePipe,
  ],
  providers: [
    provideEnvironmentNgxMask(),
    { provide: NgbDateParserFormatter, useClass: NgbDateGBParserFormatter },
    DecimalPipe,
  ],
})
export class UiKitModule {}
